
import Vue from 'vue';
import Component from 'vue-class-component';
import WarehouseAdministrationContent from './WarehouseAdministrationContent.vue';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { Prop } from 'vue-property-decorator';
import { TYPES } from '@/core/config/Types';
import { PreRegisterFindForDashboard } from '@/wms/application/preRegister/search/PreRegisterFindForDashboard';
import { Inject } from 'inversify-props';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import ConsigneeSearch from '@/core/components/shared/ConsigneeSearch.vue';
import { PreRegisterStatusEnum } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import CommoditySearch from '@/core/components/shared/CommoditySearch.vue';
import TimelineHistoryPanel from '@/wms/infrastructure/ui/historyPanel/components/Timeline.vue';
import { PreRegisterForDashboard } from '@/wms/domain/preRegister/PreRegisterForDashboard';
import TerritorySearch from '@/core/components/shared/TerritorySearch.vue';
import { DashboardAdministrationFilters } from '@/filters/domain/Filters';
import { FindPaymentsForPreRegister } from '@/courier/application/uses_cases/DocumentsV2/search/FindPaymentsForPreRegister';
import { DocumentsHeaderForTable } from '@/courier/domain/DocumentsV2/Projections/DocumentsHeaderForTable';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { FindBulkByOperation } from '@/wms/application/bulk/search/FindBulkByOperation';

@Component({
  name: 'WarehouseAdministrationCenter',
  components: {
    WarehouseAdministrationContent,
    CustomTableN,
    EntitySearch,
    ConsigneeSearch,
    CommoditySearch,
    TimelineHistoryPanel,
    TerritorySearch
  }
})
export default class WarehouseAdministrationCenter extends Vue {
  @Inject(TYPES.PRE_REGISTER_FIND_FOR_DASHBOARD)
  private readonly preRegisterFindForDashboard!: PreRegisterFindForDashboard;
  @Inject(TYPES.PRE_REGISTER_FIND_PAYMENTS)
  private readonly preRegisterFindPayments!: FindPaymentsForPreRegister;
  @Inject(TYPES.BULK_FIND_BY_OPERATION_FOR_TABLE)
  private readonly bulkFindByOperationForTable!: FindBulkByOperation;

  @Prop({ required: false, default: false }) isMainDashboard!: boolean;

  // #region Listas
  preRegisterData: PreRegisterForDashboard[] = [];
  selected: string[] = [];

  //data
  columnWidthResizeOption = {
    enable: true,
    minWidth: 10
  };
  cellStyleOption = {
    headerCellClass: ({ column }: any) => {
      if (column.key === 'origindata' || column.key === 'adresseedetails') {
        return 'table-header-cell-class';
      }
    }
  };
  isLoading = false;
  platformOptions = [
    { name: 'WMS', value: 'WMS' },
    { name: 'WCS', value: 'WCS' }
  ];
  deliveryOptions = [
    { name: 'Delivered', value: true },
    { name: 'Not Delivered', value: false }
  ];
  repackingOptions = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ];
  destinationOptions = [];
  formFilter: DashboardAdministrationFilters = {
    type: { name: 'Warehouse', value: 'WMS' },
    initialDate: new Date().toISOString().substr(0, 10),
    dueDate: new Date().toISOString().substr(0, 10),
    commodity: null,
    store: {
      inf: '',
      sup: ''
    },
    masterNumber: '',
    operationNumber: '',
    shipper: '',
    consignee: null,
    agent: null,
    carrier: null,
    state: null,
    origin: null,
    destination: null
  };

  searchTypeOptions = [
    { name: 'Warehouse', value: 'WMS' },
    { name: 'Cargo', value: 'cargo' },
    { name: 'Delivery', value: 'delivery ' }
  ];
  options = [
    {
      item: 'date',
      name: 'Date'
    },
    {
      item: 'arrived',
      name: 'Arrived'
    }
  ];

  get paymentColumns() {
    return [
      {
        field: 'type',
        key: 'type',
        title: this.$t('general.type')
      },
      {
        field: 'number',
        key: 'number',
        title: this.$t('general.number')
      },
      {
        field: 'appliedValue',
        key: 'appliedValue',
        title: this.$t('general.value')
      },
      {
        field: 'customer',
        key: 'customer',
        title: this.$t('general.customer')
      },
      {
        field: 'date',
        key: 'date',
        title: this.$t('general.date')
      }
    ];
  }

  get bulksColumns() {
    return [
      {
        field: 'sequence',
        key: 'sequence',
        title: this.$t('general.sequence')
      },
      {
        field: 'reference',
        key: 'reference',
        title: this.$t('general.reference'),
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', `${row.reference.code} - ${row.reference.filterBrand}`);
        }
      },
      {
        field: 'newConsignee',
        key: 'newConsignee',
        title: this.$t('general.consignee')
      },
      {
        field: 'newShipper',
        key: 'newShipper',
        title: this.$t('general.shipper')
      },
      {
        field: 'width',
        key: 'width',
        title: this.$t('general.dimentions'),
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', `${row.lengthOnCube} x ${row.widthOnCube} x ${row.heightOnCube}`);
        }
      }
    ];
  }

  fieldList: any = {
    WMS: [
      {
        field: 'events',
        key: 'events',
        title: this.$t('menu.Timeline'),
        width: 500,
        align: 'left',
        renderBodyCell: ({ row }: any, h: any) => {
          return h(TimelineHistoryPanel, {
            props: {
              master: row,
              masterNumber: row.number,
              houses: [],
              lineSerial: '',
              preregisterType: row.type,
              typeOperation: row.typeOperationData?.description,
              typeTransport: row.typeTransport?.name,
              items: row?.events ?? [],
              customModalIdGenerator: this.customModalIdGenerator(row),
              searchFunction: this.searchOperations
            }
          });
        }
      },
      {
        title: `${this.$t('general.cargoType')}`,
        field: 'cargoType',
        key: 'cargoType',
        width: 192,
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', `${this.$t('general.' + row.cargoType)}`);
        }
      },
      {
        title: `${this.$t('general.master')}`,
        field: 'master',
        key: 'master',
        width: 192,
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', `${row.masterNumber}`);
        }
      },
      {
        title: `${this.$t('general.operationNumber')}`,
        field: 'number',
        key: 'number',
        width: 192
      },
      {
        title: `${this.$t('general.warehouseReceipt')}`,
        field: 'type',
        key: 'type',
        width: 192,
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', `${row.type} - ${row.number}`);
        }
      },
      {
        title: `${this.$t('general.date')}`,
        field: 'date',
        key: 'date',
        width: 100
      },
      {
        title: `${this.$t('general.transporter')}`,
        field: 'carrier',
        key: 'carrier',
        width: 192
      },
      {
        title: `${this.$t('general.agent')}`,
        field: 'generalShipper',
        key: 'generalShipper',
        width: 192
      },
      {
        title: `${this.$t('general.shipper')}`,
        field: 'bulkShipper',
        key: 'bulkShipper',
        width: 192
      },
      {
        title: `${this.$t('general.consignee')}`,
        field: 'consignee',
        key: 'consignee',
        width: 192
      },
      {
        title: `${this.$t('general.origin')}`,
        field: 'origin',
        key: 'origin',
        width: 400
      },
      {
        title: `${this.$t('general.destination')}`,
        field: 'address',
        key: 'address',
        width: 400
      },
      {
        title: `${this.$t('general.store')}`,
        field: 'store',
        key: 'store',
        width: 192
      },
      {
        title: `${this.$t('general.commodity')}`,
        field: 'commodityId',
        key: 'commodityId',
        width: 192,
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', `${row.commodityId} - ${row.commodityDescription}`);
        }
      },
      {
        title: `${this.$t('general.value')}`,
        field: 'value',
        key: 'value',
        width: 100
      },
      {
        title: `${this.$t('general.pieces')}`,
        field: 'pieces',
        key: 'pieces',
        width: 100
      },
      {
        title: `${this.$t('general.weight')}`,
        field: 'weight',
        key: 'weight',
        width: 100
      },
      {
        title: `${this.$t('general.weightVol')}`,
        field: 'volumeWeight',
        key: 'volumeWeight',
        width: 120
      },
      {
        title: `${this.$t('general.volume')}`,
        field: 'volume',
        key: 'volume',
        width: 100
      },
      {
        title: `${this.$t('general.invoiceDate')}`,
        field: 'invoiceDate',
        key: 'invoiceDate',
        width: 100
      },
      {
        title: `${this.$t('general.payment receipt')}`,
        field: 'payments',
        key: 'payments',
        width: 100,
        renderBodyCell: ({ row }: any, h: any) => {
          if (row.payments.length > 1) {
            return h(
              'a',
              { attrs: { class: 'link', size: 'sm', href: '#' }, on: { click: () => this.openPaymentsModal(row) } },
              this.$t('general.see more')
            );
          }

          if (row.payments.length === 0) {
            return h('span', '-');
          }

          return h('span', `${row.payments[0].type} - ${row.payments[0].number}`);
        }
      },
      {
        title: `${this.$t('general.payment receipt date')}`,
        field: '',
        key: 'paymentReceiptDate',
        width: 100,
        renderBodyCell: ({ row }: any, h: any) => {
          if (row.payments.length === 0) {
            return h('span', '-');
          }

          if (row.payments.length > 1) {
            return h(
              'a',
              { attrs: { class: 'link', size: 'sm', href: '#' }, on: { click: () => this.openPaymentsModal(row) } },
              this.$t('general.see more')
            );
          }

          return h('span', `${row.payments[0].date.substr(0, 10)}`);
        }
      },
      {
        title: `${this.$t('general.status')}`,
        field: 'state',
        key: 'state',
        width: 100,
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', this.$t('preRegisterStatus.' + row.state));
        }
      },
      {
        title: `${this.$t('general.details')}`,
        field: 'details',
        key: 'details',
        width: 100,
        renderBodyCell: ({ row }: any, h: any) => {
          return h(
            'a',
            { attrs: { class: 'link', size: 'sm', href: '#' }, on: { click: () => this.openDetailsModal(row) } },
            this.$t('general.see more')
          );
        }
      }
    ]
  };

  columnFields = this.fieldList['WMS'];
  selectedPreRegister: PreRegisterForDashboard | null = null;
  paymentList: DocumentsHeaderForTable[] = [];
  bulksList: Bulk[] = [];

  setFields(
    type: {
      name: string;
      value: string;
    } | null
  ) {
    if (!type) {
      return;
    }

    this.columnFields = this.fieldList[type.value];
  }

  //Objeto de acciones para la tabla de items
  tableActions = {
    customActions: [
      { title: `${this.$t('general.delete')}`, icon: 'fa fa-pencil', variant: 'secondary', action: '' },
      { title: `${this.$t('general.delete')}`, icon: 'fa fa-trash', variant: 'danger', action: '' },
      { title: `${this.$t('general.details')}`, icon: 'fa fa-eye', variant: 'warning', action: '' }
    ]
  };

  get preRegisterStateOptions() {
    return Object.keys(PreRegisterStatusEnum).map(key => {
      return {
        name: this.$t(`preRegisterStatus.${key}`),
        value: PreRegisterStatusEnum[key as keyof typeof PreRegisterStatusEnum]
      };
    });
  }

  get searchDisabled() {
    if (!this.formFilter.type || !this.formFilter.initialDate || !this.formFilter.dueDate) {
      return true;
    } else {
      return false;
    }
  }
  async searchOperations() {
    this.isLoading = true;
    try {
      if (!this.formFilter.type) {
        return;
      }
      this.isLoading = true;
      const res = await this.preRegisterFindForDashboard.internalExecute(this.formFilter);
      this.preRegisterData = res;
    } finally {
      this.isLoading = false;
    }
  }

  customModalIdGenerator(row: PreRegisterForDashboard) {
    return `modal-event-${new Date().getTime()}-${row.number}`;
  }

  clearFilters() {
    this.formFilter = {
      initialDate: new Date().toISOString().substr(0, 10),
      dueDate: new Date().toISOString().substr(0, 10),
      carrier: null,
      consignee: null,
      shipper: '',
      agent: null,
      type: { name: 'Warehouse', value: 'WMS' },
      state: null,
      commodity: null,
      store: {
        inf: '',
        sup: ''
      },
      operationNumber: '',
      origin: null,
      destination: null,
      masterNumber: ''
    };
  }

  async openPaymentsModal(row: PreRegisterForDashboard) {
    this.selectedPreRegister = row;
    await this.findPayments();
    this.$bvModal.show('paymentModal');
  }

  async openDetailsModal(row: PreRegisterForDashboard) {
    this.selectedPreRegister = row;
    await this.findBulks();
    this.$bvModal.show('bulksModal');
  }

  async findPayments() {
    if (!this.selectedPreRegister) {
      return;
    }

    try {
      this.isLoading = true;
      const payments = await this.preRegisterFindPayments.internalExecute({
        masterNumber: this.selectedPreRegister.number,
        masterType: this.selectedPreRegister.type,
        dateFrom: null,
        dateTo: null,
        entity: null
      });
      this.paymentList = payments;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async findBulks() {
    if (!this.selectedPreRegister) {
      return;
    }

    try {
      this.isLoading = true;
      const bulks = await this.bulkFindByOperationForTable.internalExecute({
        number: this.selectedPreRegister.number,
        type: this.selectedPreRegister.type
      });

      this.bulksList = bulks;
    } catch (error) {
      throw new Error('Error al buscar los bultos');
    } finally {
      this.isLoading = false;
    }
  }
}
